@font-face {
  font-family: 'Novozymes Bold';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Novozymes-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Novozymes Regular';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Novozymes-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Novozymes Thin';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/Novozymes-Thin.otf') format('opentype');
}

@font-face {
  font-family: 'Novozymes Italic';
  font-weight: normal;
  font-style: normal;
  src: url('./fonts/NOVOZYMES-ITALIC.otf') format('opentype');
}

.App {
  font-family: 'Novozymes Regular', 'Arial';
}
